@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Calibre','Inter','San Francisco','SF Pro Text',
    sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.steering-down{
  transform: translateY(.5px) rotate(45deg);  
}
.steering-up{
  transform: translateY(-.5px) rotate(-45deg);
}

.nav-ul{      
  transition: .5s ease;
}
.profile-background{
  width: 270px;
  height: 300px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(0deg, rgba(13, 13, 79, 0.7), rgba(13, 13, 90, 0.5)), url(/src/assets/profile.jpg);
 
}
.css-1q2h7u5.Mui-selected{
  color: #FFD400!important;
} 

@media  (min-width: 768px) {
  .profile-background{
    width: 100%;
  }
}
